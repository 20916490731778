import "./App.css";
import { CustomErrorBoundary } from "./components/CustomErrorBoundry";
import { AppScreen } from "./ui";
import HomeScreen from "./screens/Home";
import { Routes, Route, useLocation, useSearchParams } from "react-router-dom";
import PredictionScreen from "./screens/Prediction";
import { useAppStore } from "./store";
import { useCallback } from "react";
import { SSO_REQUEST } from "./api";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import ga4 from 'react-ga4';
import TagManager from "react-gtm-module";




const tagManagerArgs = {
  gtmId: "GTM-5GFRM4X",
};

TagManager.initialize(tagManagerArgs);

function App() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { setIsAppLoading, setIsLogin, setUser, isSSoError, setIsSSoError } = useAppStore();


  useEffect(() => {
    ga4.initialize("G-J014X45MLQ");
  }, [])

  //Single Sign in with super app
  const SSO_handle = useCallback(async () => {
    if (searchParams.has("token")) {
      //'IOS_SUPERAPP','ANDROID_SUPERAPP','IOS_SHELL','ANDROID_SHELL','PWA'
      let response = await SSO_REQUEST({
        token: searchParams.get("token"),
        platform: "PWA",
      });
      if (response?.success) {
        setIsAppLoading(false);
        setIsSSoError(false);
        localStorage.setItem("perdition_token", response?.data?.token);
        localStorage.setItem(
          "perdition_user",
          JSON.stringify(response?.data?.user)
        );
        localStorage.setItem(
          "perdition_refresh_token",
          response?.data?.refreshToken
        );
        setIsLogin(true);
        setUser(response?.data?.user);
      } else {
        setIsAppLoading(false);
        setIsSSoError(true);
        console.log(response?.err);
      }
    } else {
      setIsAppLoading(false);
      //setIsSSoError(true);
    }
  }, [searchParams]);

  useEffect(() => {
    SSO_handle();
  }, [SSO_handle]);



  return (
    <CustomErrorBoundary>
      <Toaster />
      <AppScreen>
        <Routes location={location} key={location?.pathname}>
          <Route exact path="/" element={<HomeScreen />} />
          <Route path="/prediction" element={<PredictionScreen />} />
        </Routes>
      </AppScreen>
    </CustomErrorBoundary>
  );
}

export default App;
