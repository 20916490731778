import ScreenContent from "../../components/ScreenContent";
import "./style.css";
import { IoIosArrowForward } from "react-icons/io";
import { PureIconButton, PureButton } from "../../ui";
import { useNavigate } from "react-router-dom";
import { _goToLink } from "../../helper/goLink";
import { useState, useEffect } from "react";
import { useAppStore } from "../../store";
import { storePrediction } from "../../api";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import toast from "react-hot-toast";

const PredictionScreen = () => {
  const navigate = useNavigate();
  const [no1, setNo1] = useState(null);
  const [no2, setNo2] = useState(null);
  const { setMatcheInfo, matcheInfo } = useAppStore();

  const { mutate, isLoading } = useMutation(storePrediction, {
    onSuccess: (data) => {
      if (data.success) {
        setMatcheInfo(null);
      } else {
        toast.error(data.err);
      }
    },
    onError: (e) => {
      toast.error("خطأ بالخادم!");
    },
  });

  useEffect(() => {
    if (!matcheInfo) {
      navigate(-1);
    }
  }, [matcheInfo]);

  const handleSend = () => {
    mutate({
      matchID: matcheInfo?.id,
      scoreA: Number(no1),
      scoreB: Number(no2),
    });
  };

  const isSend = () => {
    return no1 && no2;
  };

  return (
    <div className="prediction-screen">
      <ScreenContent fullScreen>
        <section className="header">
          <div className="overlay">
            <div className="app-container header-info">
              <PureIconButton
                onClick={() => _goToLink(() => navigate(-1))}
                icon={<IoIosArrowForward color="#fff" size={28} />}
              />
              <div className="info">
                <b>{`مباراة ${matcheInfo?.teamA?.name} و ${matcheInfo?.teamB?.name}`}</b>
                <p>
                  {dayjs(matcheInfo?.startDate)
                    .format("hh:mm A")
                    .replace("AM", "ص")
                    .replace("PM", "م")}{" "}
                  | {matcheInfo?.label || "..."}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="match">
            <div className="team">
              <img src={matcheInfo?.teamA?.url} />
              <p>{matcheInfo?.teamA?.name}</p>
            </div>
            <span>-</span>
            <div className="team">
              <img src={matcheInfo?.teamB?.url} />
              <p>{matcheInfo?.teamB?.name}</p>
            </div>
          </div>

          <div className="form">
            <input
              placeholder="0"
              value={no1}
              onChange={(e) => setNo1(e.target.value)}
              type="number"
            />
            <p>-</p>
            <input
              placeholder="0"
              value={no2}
              onChange={(e) => setNo2(e.target.value)}
              type="number"
            />
          </div>
        </section>
        <section className="send-btn">
          <div className="app-container">
            <PureButton
              disabled={!isSend() || isLoading}
              block
              primary
              size="larg"
              onClick={handleSend}
            >
              {isLoading ? <div className="btn-loading" /> : "ارسال التوقع"}
            </PureButton>
          </div>
        </section>
      </ScreenContent>
    </div>
  );
};

export default PredictionScreen;
