import "./style.css";
import { useRef } from "react";
import { useRipple } from "react-use-ripple";

const BannerCard = ({ url, ...props }) => {
  const ref = useRef();
  useRipple(ref);
  return (
    <div ref={ref} className="banner-card" {...props}>
      <img src={url} />
    </div>
  );
};

export default BannerCard;
