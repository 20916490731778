export const BalyShare = ({ ...props }) => {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 4.5H5C5.82843 4.5 6.5 5.17157 6.5 6V15C6.5 15.2761 6.27614 15.5 6 15.5H1C0.723858 15.5 0.5 15.2761 0.5 15V6C0.5 5.17157 1.17157 4.5 2 4.5Z"
        fill="white"
        fill-opacity="0.24"
        stroke="white"
      />
      <path
        d="M10 0.5H13C13.8284 0.5 14.5 1.17157 14.5 2V15C14.5 15.2761 14.2761 15.5 14 15.5H9C8.72386 15.5 8.5 15.2761 8.5 15V2C8.5 1.17157 9.17157 0.5 10 0.5Z"
        fill="white"
        fill-opacity="0.24"
        stroke="white"
      />
      <path
        d="M18 8.5H21C21.8284 8.5 22.5 9.17157 22.5 10V15C22.5 15.2761 22.2761 15.5 22 15.5H17C16.7239 15.5 16.5 15.2761 16.5 15V10C16.5 9.17157 17.1716 8.5 18 8.5Z"
        fill="white"
        fill-opacity="0.24"
        stroke="white"
      />
    </svg>
  );
};
