import "./style.css";

export const AppScreen = ({ children, ...props }) => {
  return (
    <div className="app-screen" {...props}>
      {children}
    </div>
  );
};

