import "./style.css";
import { Space } from "../../ui";
import { BalyShare, BalyScore, BalyCrown } from "../../ui/Icons";

const ScorCard = ({ data, ...props }) => {
  return (
    <div className="scor-card" {...props}>
      <div className="app-flex-space">
        <b>{`يا هلا, ${data?.firstName || "..."}`}</b>
        {/* <PureButton icon={<AiOutlineShareAlt />} size="small">
          شارك واربح اكثر
        </PureButton> */}
      </div>

      <Space height={16} />
      <div className="scor-info">
        <div className="scor-item">
          <BalyScore />
          <p>النقاط</p>
          <b>{data?.pointsTotal}</b>
        </div>
        <span className="divider"></span>
        <div className="scor-item">
          <BalyCrown />
          <p>توقع صحيح</p>
          <b>{data?.correctPredictionTotal}</b>
        </div>
        <span className="divider"></span>
        <div className="scor-item">
          <BalyShare />
          <p>مشاركات</p>
          <b>{data?.predictionTotal}</b>
        </div>
      </div>
    </div>
  );
};

export default ScorCard;
