export const BalyCrown = ({ ...props }) => {
  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.00443 5.37696L1.7036 12.7749C4.8327 10.9039 12 8.15516 20.2964 12.7749L20.9956 5.37696C21.056 4.73735 20.4871 4.25155 19.9446 4.47953L15.6399 6.28854C15.3206 6.42274 14.9596 6.31074 14.7496 6.01229L11.6142 1.55792C11.3023 1.11481 10.6977 1.11481 10.3858 1.55792L7.25043 6.01229C7.04035 6.31074 6.6794 6.42274 6.36008 6.28854L2.05538 4.47953C1.51288 4.25155 0.943981 4.73735 1.00443 5.37696Z"
        fill="white"
        fill-opacity="0.24"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
