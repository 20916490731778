export const BalyCrownGold = ({ ...props }) => {
  return (
    <svg
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      { ...props }
    >
      <path
        d="M1.00443 5.94336L1.7036 13.3413C4.8327 11.4703 12 8.72157 20.2964 13.3413L20.9956 5.94337C21.056 5.30376 20.4871 4.81796 19.9446 5.04593L15.6399 6.85495C15.3206 6.98914 14.9596 6.87715 14.7496 6.5787L11.6142 2.12432C11.3023 1.68122 10.6977 1.68122 10.3858 2.12432L7.25043 6.5787C7.04035 6.87715 6.6794 6.98914 6.36008 6.85495L2.05538 5.04593C1.51288 4.81796 0.943981 5.30375 1.00443 5.94336Z"
        fill="#FFDC84"
        stroke="#F0AD01"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
