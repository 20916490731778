const SSO_URL =
  process.env.REACT_APP_IS_DEV !== "true"
    ? "https://app.food.baly.iq/api/v1"
    : "https://app.baly.dev/api/v1";

const URL = process.env.NODE_ENV === "production"
  ? ""
  : "https://prediction.baly.dev";

export const pageSize = 10;
export const imgResolution = 60;

export const SSO_REQUEST = async (data) => {
  try {
    const res = await fetch(
      `${SSO_URL}/../auth/signon?token=${data?.token}&platform=${data?.platform}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return res.json();
  } catch (err) {
    console.log("Error has occured :" + err);
  }
};

export const getMatches = async (e) => {
  try {
    let token = localStorage.getItem("perdition_token")
    if (!token) throw 'login needed'
    const res = await fetch(`${URL}/api/matches`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        //Authorization: testToken,
      },
    });

    return res.json();
  } catch (err) {
    console.log("Error has occured :" + err);
  }
};

export const storePrediction = async (data) => {
  try {
    let token = localStorage.getItem("perdition_token")
    if (!token) throw 'login needed'
    const res = await fetch(`${URL}/api/prediction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        //Authorization: testToken,
      },

      body: JSON.stringify(data),
    });

    return res.json();
  } catch (err) {
    console.log("Error has occured :" + err);
  }
};
