import "./style.css";
import { PureButton, Space, PureLabel } from "../../ui";
import { useNavigate } from "react-router-dom";
import { _goToLink } from "../../helper/goLink";
import dayjs from "dayjs";
import { useEffect } from "react";
import Countdown from "react-countdown";
import { useAppStore } from "../../store";
import { BalyCrownGold } from "../../ui/Icons";

const HomeCard = ({
  status,
  prediction,
  startDate,
  teamA,
  teamB,
  id,
  label,
  ...props
}) => {
  const navigate = useNavigate();
  const { setMatcheInfo } = useAppStore();

  const renderResults = () => {
    let data = null;
    if (
      status === "OPEN_PREDICTION" &&
      dayjs(startDate).diff(dayjs(), "h") >= 1
    ) {
      data = (
        <div className="info">
          <b>
            {dayjs(startDate)
              .format("hh:mm A")
              .replace("AM", "ص")
              .replace("PM", "م")}
          </b>
        </div>
      );
    } else if (
      status === "OPEN_PREDICTION" &&
      dayjs(startDate).diff(dayjs(), "m") >= 1 &&
      dayjs(startDate).diff(dayjs(), "h") <= 1
    ) {
      data = (
        <div className="info">
          <b>ستبدأ المباراة</b>
          <p>
            <Countdown overtime={false} daysInHours={true} date={startDate} />
          </p>
        </div>
      );
    } else if (status === "MISSED_PREDICTION") {
      data = (
        <div className="info">
          {teamA?.result !== -1 && teamB?.result !== -1 ? (
            <>
              <b>{`${teamA?.result} - ${teamB?.result}`}</b>
              <p>النتيجة</p>
            </>
          ) : (
            <b>-</b>
          )}
        </div>
      );
    } else if (status === "WINNER_PREDICTED" || status === "FAIL_PREDICTION") {
      data = (
        <div className="info">
          <b>{`${prediction?.scoreA} - ${prediction?.scoreB}`}</b>
          <p>{`النتيجة ${teamA?.result} - ${teamB?.result}`}</p>
        </div>
      );
    } else if (
      prediction &&
      status !== "MISSED_PREDICTION" &&
      status !== "OPEN_PREDICTION"
    ) {
      data = (
        <div className="info">
          <b>{`${prediction?.scoreA} - ${prediction?.scoreB}`}</b>
          <p>توقعك</p>
        </div>
      );
    } else {
      data = (
        <div className="info">
          <b>-</b>
        </div>
      );
    }
    return data;
  };

  useEffect(() => {
    renderResults();
  }, []);

  const actions = {
    FAIL_PREDICTION: (
      <div className="action">
        <PureLabel style={{ flex: 1 }}>لم تتوقع بشكل صحيح</PureLabel>
        <Space width={8} />
        <PureLabel>0 نقطة</PureLabel>
      </div>
    ),
    MISSED_PREDICTION:
      teamA?.result !== -1 ? (
        <div className="action">
          <PureLabel block>لم ترسل توقعك</PureLabel>
        </div>
      ) : (
        <>
          <div className="action">
            <span className="dot" />
            <Space width={8} />
            <p>بدأت المباراة, لم ترسل توقعك</p>
          </div>
          <Space height={10} />
        </>
      ),

    WINNER_PREDICTED: (
      <div className="action">
        <PureLabel style={{ flex: 1 }} type="warning">
          توقعت الفريق الفائز بشكل صحيح
        </PureLabel>
        <Space width={8} />
        <PureLabel type="warning">{`+${prediction?.point} نقطة`}</PureLabel>
      </div>
    ),

    RESULT_PREDICTED: (
      <div className="action">
        <PureLabel type="success" style={{ flex: 1 }}>
          مبروك, توقعك صحيح
        </PureLabel>
        <Space width={8} />
        <PureLabel type="success">{`+${prediction?.point} نقطة`}</PureLabel>
      </div>
    ),

    OPEN_PREDICTION: (
      <div className="action">
        <PureButton
          block
          onClick={() => {
            setMatcheInfo({
              startDate,
              teamA,
              teamB,
              label,
              id,
            });
            _goToLink(() => navigate(`/prediction`));
          }}
        >
          توقع نتيجة المباراة
        </PureButton>
      </div>
    ),

    SUBMITTED: dayjs().isBefore(dayjs(startDate)) ? (
      <>
        <div className="action">
          <p>
            ستبدأ المباراة{" "}
            <Countdown overtime={false} daysInHours={true} date={startDate} />
          </p>
        </div>
        <Space height={10} />
      </>
    ) : (
      <>
        <div className="action">
          <span className="dot" />
          <Space width={8} />
          <p>بدأت المباراة</p>
        </div>
        <Space height={10} />
      </>
    ),
  };

  return (
    <div className="home-card" {...props}>
      <div className="body">
        <div
          className={
            status === "OPEN_PREDICTION" ? "team" : "team teamRow teamA"
          }
        >
          {status !== "OPEN_PREDICTION" &&
            status !== "SUBMITTED" &&
            teamA?.result > teamB?.result && <BalyCrownGold />}
          <img src={teamA?.url} />
          <p>{teamA?.name}</p>
        </div>
        {renderResults()}

        <div
          className={
            status === "OPEN_PREDICTION" ? "team" : "team teamRow teamB"
          }
        >
          {status !== "OPEN_PREDICTION" &&
            status !== "SUBMITTED" &&
            teamB?.result > teamA?.result && <BalyCrownGold />}
          <img src={teamB?.url} />
          <p>{teamB?.name}</p>
        </div>
      </div>

      {actions[status]}
      {/* <PureButton
          block
          onClick={() => {
            setMatcheInfo({
              startDate,
              teamA,
              teamB,
              id,
            });
            _goToLink(() => navigate(`/prediction`));
          }}
        >
          توقع نتيجة المباراة
        </PureButton> */}
      {/* <p>ستبدأ المباراة 00:23:25</p> */}
      {/* <span className="dot" />
        <Space width={8} />
        <p>بدأت المباراة</p> */}
      {/* <span className="dot"/>
        <Space width={8}/>
        <p>بدأت المباراة, لم ترسل توقعك</p> */}

      {/* <PureLabel type="success" style={{flex: 1}} >توقعك كان صحيحاً, دزينالك جائزتك برسالة</PureLabel>
         <Space width={8}/>
        <PureLabel type="success">+15 نقطة</PureLabel> */}

      {/* <PureLabel style={{flex: 1}} type="warning">توقعت الفريق الفائز بشكل صحيح</PureLabel>
         <Space width={8}/>
        <PureLabel type="warning">+5 نقاط</PureLabel> */}
    </div>
  );
};

export default HomeCard;
