import "./style.css";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { HiOutlineHome } from "react-icons/hi";
import { PureIconButton, Space } from "../../ui";
import { useAppStore } from "../../store";
const AppHeader = ({ children, ...props }) => {
  const { setIsInfo } = useAppStore();
  return (
    <div className="app-header" {...props}>
      <Space height={20} />
      <div className="app-flex-space app-container">
        <div>
          <div className="title">
            <b>بَلي گووووول!</b>
            <PureIconButton
              onClick={() => setIsInfo(true)}
              style={{ width: 'auto', height: 'auto' }}
              icon={<AiOutlineInfoCircle color="#ccc" size={20} />}
            />
          </div>
          <p className="desc">توقع نتائج المباريات، حصل نقاط وأربح خصومات نارية 🔥!</p>
        </div>
        <a href={"balyhomepage://"}>
          <PureIconButton icon={<HiOutlineHome size={24} />} />
        </a>
      </div>
    </div>
  );
};

export default AppHeader;
