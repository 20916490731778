import "./style.css";

export const PureLabel = ({
  icon,
  type,
  children,
  block = false,
  ...props
}) => {
  const types = {
    success: "ui-label-success",
    warning: "ui-label-warning",
  };

  return (
    <button
      className={type ? `ui-label ${types[type]}` : "ui-label"}
      style={{
        width: block ? '100%' : 'fit-content'
      }}
      {...props}
    >
      <p>{children}</p>
    </button>
  );
};
