import "./style.css";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";

import { useState, useEffect } from "react";

export const Slider = ({ items }) => {
  const [current, setCurrent] = useState(0);

  return (
    <div className="app-slider">
      <Swiper
        spaceBetween={0}
        modules={[Autoplay]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        slidesPerView={1}
        onSlideChange={(e) => setCurrent(e?.activeIndex)}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {items?.map((el, i) => (
          <SwiperSlide key={i}>
            <div
              className="app-container"
              style={{ marginBottom: items?.length > 1 ? 6 : -3 }}
            >
              {el}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swip-dots">
        {items?.length > 1 &&
          items?.map((el, i) => (
            <span
              key={i}
              className={i === current ? "dot dot-active" : "dot"}
            />
          ))}
      </div>
    </div>
  );
};
