import "./style.css";
import AppHeader from "../../components/Header";
import ScreenContent from "../../components/ScreenContent";
import BannerCard from "../../components/BannerCard";
import { Space, Slider, Divider, Empty } from "../../ui";
import ScorCard from "../../components/ScorCard";
import HomeList from "../../components/HomeList";
import { useAppStore } from "../../store";
import { Skeleton } from "../../components/HomeSkeleton";
import { useQuery } from "react-query";
import { getMatches } from "../../api";
import InfoModal from "../../components/InfoModal";

// import ReactPullToRefresh from "react-pull-to-refresh";

const HomeScreen = () => {
  const { isAppLoading, isSSoError } = useAppStore();

  const { error, isLoading, data, refetch } = useQuery(
    ["matches"],
    getMatches,
    {
      enabled: !isAppLoading && !isSSoError,
      refetchOnWindowFocus: true,
      retry: 2,
    }
  );

  // const handleRefresh = async () => {
  //   refetch();
  // };

  return (
    <div className="home-screen">
      <AppHeader />
      {(isAppLoading || isLoading) ? (
        <Skeleton />
      ) : (
        // : isSSoError ? (
        //   <div style={{ marginTop: "34vh" }}>
        //     <Empty
        //       screen={"COMMENTS"}
        //       action={() => {
        //         window.location.reload();
        //       }}
        //       actionText="اعادة المحاولة"
        //       title="خطأ بتسجيل الدخول!"
        //       msg={"حدثت مشكلة اثناء تسجيل الدخول حاول مجددا."}
        //     />
        //   </div>
        // )
        // <ReactPullToRefresh
        //   onRefresh={handleRefresh}
        //   loading={<div className="loading">
        //   <span className="loading-ptr-1"></span>
        //   <span className="loading-ptr-2"></span>
        //   <span className="loading-ptr-3"></span>
        // </div>}
        //   style={{ textAlign: "center" }}
        // >
        <ScreenContent>
          <Space height={16} />
          <Slider
            items={data?.data?.Banners?.map((el) => (
              <BannerCard
                onClick={() => {
                  if (el?.action && el?.action !== "undefined") {
                    window.location.href = el?.action;
                  }
                }}
                key={el.id}
                action={el?.action}
                url={el.img}
              />
            ))}
          />
          <Space height={16} />
          <section className="app-container">
            <ScorCard data={data?.data?.User} />
            <Divider />
            <HomeList list={data?.data?.Matches || []} />
            <Space height={80} />
          </section>
        </ScreenContent>
        // </ReactPullToRefresh>
      )}
      <InfoModal />
    </div>
  );
};

export default HomeScreen;
