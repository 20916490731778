import "./style.css";
import { useRef } from "react";
import { useRipple } from "react-use-ripple";

export const PureIconButton = ({ icon, ...props }) => {
  const ref = useRef();
  useRipple(ref);
  return (
    <button className="ui-icon-btn" ref={ref} {...props}>
      {icon}
    </button>
  );
};

