import create from "zustand";

export const useAppStore = create((set) => ({
  isLogin: false,
  user: null,
  isAppLoading: true,
  matcheInfo: null,
  isInfo: false,
  isSSoError: false,

  setIsSSoError: (isSSoError) => set({ isSSoError }),
  setIsInfo: (isInfo) => set({ isInfo }),
  setMatcheInfo: (matcheInfo) => set({ matcheInfo }),
  setIsAppLoading: (isAppLoading) => set({ isAppLoading }),
  setUser: (user) => set({ user }),
  setIsLogin: (isLogin) => set({ isLogin }),
}));
