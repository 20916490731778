export const BalyScore = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 2.61489C7.20916 4.38817 8.62045 5.79747 10.3951 6.50399C8.63291 7.21396 7.21627 8.62201 6.50302 10.3927C5.79619 8.61917 4.38736 7.20883 2.61489 6.5C4.38484 5.79218 5.79218 4.38484 6.5 2.61489Z"
        fill="white"
        fill-opacity="0.24"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M17.5 7.41206C18.1036 8.79516 19.2124 9.90226 20.5966 10.5038C19.2216 11.1078 18.1097 12.2138 17.5029 13.5946C16.9012 12.2113 15.7945 11.1034 14.4121 10.5C15.7923 9.89762 16.8976 8.79226 17.5 7.41206Z"
        fill="white"
        fill-opacity="0.24"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M11.5 15.1908C11.9892 16.1994 12.807 17.0159 13.8165 17.5035C12.8134 17.9928 11.9941 18.8083 11.5028 19.8149C11.0151 18.8062 10.1988 17.989 9.19084 17.5C10.1969 17.012 11.012 16.1969 11.5 15.1908Z"
        fill="white"
        fill-opacity="0.24"
        stroke="white"
        stroke-linejoin="round"
      />
    </svg>
  );
};
