import "./style.css";
import { BalyBackground } from "../../ui/Icons";
import HomeCard from "./card";
import dayjs from "dayjs";
import { Space, Divider } from "../../ui";

const parseDate = (_date) => {
  const month = [
    "يناير",
    "شهر فبراير",
    "يمشي",
    "أبريل",
    "مايو",
    "يونيه",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "اكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];

  const daysInWeek = [
    "الأحد",
    "الاثنين",
    "الثلاثاء",
    "الأربعاء",
    "الخميس",
    "جمعة",
    "السبت",
  ];

  return `${
    (dayjs(_date).day().toString(), daysInWeek[dayjs(_date).day()])
  }, ${dayjs(_date).date()}  ${
    (dayjs(_date).month().toString(), month[dayjs(_date).month()])
  } ${dayjs(_date).year()}`;
};

const HomeList = ({ list, ...props }) => {
  return (
    <div className="home-list" {...props}>
      <div className="list-header">
        <div className="app-flex-space title">
          <div className="label">
            <b>يلا توقع النتيجة</b>
            <p>توقع صح, وحصل هواي جوائز 🥳</p>
          </div>
          <BalyBackground />
        </div>
      </div>

      <div className="list">
        {list
          ?.sort((a, b) => dayjs(b.date) - dayjs(a.date))
          ?.map((group, index) => (
            <div>
              {index !== 0 && (
                <>
                  <Space height={6} />
                  <Divider />
                  <Space height={6} />
                  <b style={{ marginRight: 4 }}>{parseDate(group?.date)}</b>
                  <Space height={10} />
                </>
              )}
              {group?.matches?.map((el) => (
                <HomeCard
                  key={el.id}
                  id={el.id}
                  label={el?.label}
                  startDate={el?.startTime}
                  prediction={el?.prediction?.matchID ? el?.prediction : null}
                  status={el?.status}
                  teamA={{
                    name: el?.teamA,
                    url: el?.logoA,
                    result: el?.resultA,
                  }}
                  teamB={{
                    name: el?.teamB,
                    url: el?.logoB,
                    result: el?.resultB,
                  }}
                />
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default HomeList;
