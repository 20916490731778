import "./style.css";
import { Divider } from "../../ui";

export const Skeleton = () => {
  return (
    <div className="home-skeleton">
      <div className="app-container">
        <div className="pure-skeleton banner"></div>
        <div className="banner-dot">
        <div className="pure-skeleton dot"></div>
        <div className="pure-skeleton dot"></div>
        <div className="pure-skeleton dot"></div>
        </div>
        <Divider />
      </div>
      <div className="app-container">
      <div className="score">
        <div className="pure-skeleton box"></div>
        <div className="pure-skeleton box"></div>
        <div className="pure-skeleton box"></div>
        </div>
        <div className="app-flex-space head">
          <div className="pure-skeleton title"></div>
          <div className="pure-skeleton share"></div>
        </div>
       
        <div className="list">
        <div className="pure-skeleton card"></div>
        <div className="pure-skeleton card"></div>
        <div className="pure-skeleton card"></div>
        <div className="pure-skeleton card"></div>
        </div>
      </div>

    </div>
  );
};
