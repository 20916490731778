import "./style.css";
import { PureModalSheet, PureIconButton, Divider } from "../../ui";
import { CgClose } from "react-icons/cg";
import { useAppStore } from "../../store";
import { Space, PureButton } from "../../ui";

const InfoModal = ({ ...props }) => {
  const { isInfo, setIsInfo } = useAppStore();

  return (
    <PureModalSheet
      viewHeight={610}
      isOpen={isInfo}
      onClose={() => setIsInfo(false)}
    >
      <div className="info-modal app-container" {...props}>
        <section className="app-flex-space header">
          <b>هلا بيك بـ بَلي گووووول!</b>
          <PureIconButton
            onClick={() => setIsInfo(false)}
            icon={<CgClose size={24} />}
          />
        </section>
        <Space height={24} />
        <section className="body">
          <h4>شروط المسابقة</h4>
          <ul>
            <li>
            أختار نتيجة توقعك قبل بداية اللعبة لان راح يتم غلق صفحة التوقعات تلقائيا وية بداية المباراة.
            </li>
            <li>
            أختار توقعك بحرص قبل لا تحدد، لان متكدر تتراجع بعدين.
            </li>
          </ul>
          <Divider />
          <Space height={10} />
          <h4>نظام النقاط</h4>
          <ul>
            <li>
            راح تحصل 15 نقطة على كل توقع صحيح.
            </li>
            {/* <li>راح توصلك 15 نقطة اضافية على مجموع نقاطك، عند كل توقع صحيح.</li> */}
            <li>
            راح تحصل 5 نقاط اذا توقعت الفائز بس النتيجة مو صحيحة.
            </li>
            {/* <li>
              راح تحصل نقاط اضافية اكثر عند مشاركة نتائج توقعاتك على صفحات
              التواصل الاجتماعي من خلال زر المشاركة على الصفحة الرئيسية.
            </li> */}
            <li>
            أصحاب أعلى مجموع نقاط بنهاية المسابقة راح يحصلون فرصة يربحون خصومات 50% على الطعام او التكسي.
            </li>
          </ul>
        </section>
        <Divider />
        <Space height={80} />
        <PureButton block primary size="larg" onClick={() => setIsInfo(false)}>
          يلا نلعب؟
        </PureButton>
      </div>
    </PureModalSheet>
  );
};

export default InfoModal;
