import "./style.css";
import { useRef } from "react";
import { useRipple } from "react-use-ripple";

export const PureButton = ({
  icon,
  primary,
  size = "normal",
  children,
  block = false,
  disabled,
  ...props
}) => {
  const ref = useRef();
  useRipple(ref);

  const sizeList = {
    normal: 16,
    larg: 18,
    small: 14,
  };

  const paddingList = {
    normal: "6px 10px",
    larg: "13px 18px",
    small: "4px 8px",
  };

  return (
    <button
      className={primary ? "ui-btn ui-btn-primary" : "ui-btn"}
      ref={ref}
      disabled={disabled}
      style={{
        fontSize: sizeList[size],
        padding: paddingList[size],
        width: block ? "100%" : "fit-contnet",
      }}
      {...props}
    >
      {children}
      {icon}
    </button>
  );
};
