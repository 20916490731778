import "./style.css";

const ScreenContent = ({ children, fullScreen, ...props }) => {
  return (
    <div
      className={fullScreen ? "screen-content-full" : "screen-content"}
      {...props}
    >
      {children}
    </div>
  );
};

export default ScreenContent;
